import request from '../index'
import qs from 'qs'

// 查询分享
export const getShareInfo = (params) =>
  request({
    method: 'get',
    url: '/share',
    stopLoading: true,
    params,
  })

// 生成分享
export const postShreInfo = (data) =>
  request({
    url: `/share`,
    method: 'post',
    data,
  })

// 查询已下载次数
export const downCount = (params) =>
  request({
    method: 'get',
    url: '/share/downLoadCount',
    stopLoading: false,
    params,
  })

// 停止分享
export const forbiddenShare = (data) =>
  request({
    method: 'put',
    url: '/share/forbiddenOpusShare',
    stopLoading: false,
    data,
  })
