<template>
  <div>
    <a-modal
      v-model="visible"
      :title="$t('shareModal.info.title')"
      @ok="handleOk"
      @cancel="cancel"
      :maskClosable="false"
      width="48%"
      center
    >
      <div class="info">
        <a-icon type="exclamation-circle" />
        <p>{{ $t('shareModal.info.ts') }}</p>
      </div>
      <a-form-model
        ref="form"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item
          ref="accessDuration"
          :label="$t('shareModal.info.validityPeriod')"
          prop="accessDuration"
        >
          <a-select
            size="large"
            :placeholder="$t('shareModal.info.placeholderPeriod')"
            v-model="form.accessDuration"
            @change="duraChange"
          >
            <a-select-option
              v-for="i in seleiconlist"
              :key="i.value"
              :label="$t('shareModal.info.space')+ i.name + $t('shareModal.info.effectiveDays')"
            >
              <span v-if="i.isShow">{{ $t('shareModal.info.space') }} </span>
              <span :class="i.isShow ? 'label' : ''">{{ i.name }}</span>
              <span v-if="i.isShow">{{ $t('shareModal.info.effectiveDays') }}</span>
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          ref="name"
          label="  "
          class="labelEmpty"
          v-if="form.accessDuration === 0"
          prop="expiredAt"
        >
          <a-date-picker
            v-model="form.expiredAt"
            type="date"
            :placeholder="$t('shareModal.info.placeholderTime')"
            style="width: 30%"
            format="YYYY-MM-DD"
            :disabled-date="disabledDate"
            @change="onChange"
          />
        </a-form-model-item>
        <a-form-model-item :label="$t('shareModal.address.accessCode')" prop="accessStatus">
          <a-radio-group v-model="form.accessStatus" @change="accessChange">
            <a-radio value="0">{{ $t('shareModal.info.accessCode.radio1') }}</a-radio>
            <a-radio value="1">{{ $t('shareModal.info.accessCode.radio2') }}</a-radio>
            <a-radio value="2">{{ $t('shareModal.info.accessCode.radio3') }}</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item
          label="  "
          class="labelEmpty"
          v-if="form.accessStatus == 1"
          prop="accessCode"
        >
          <a-input
            v-model="form.accessCode"
            :placeholder="$t('shareModal.info.placeholderAccessCode')"
            :maxLength="6"
          />
        </a-form-model-item>
        <a-form-model-item :label="$t('shareModal.info.permission.lable')" prop="shareType">
          <a-radio-group v-model="form.shareType" @change="shareChange">
            <a-radio value="0">{{ $t('shareModal.info.permission.radio1') }}</a-radio>
            <a-radio value="1">{{ $t('shareModal.info.permission.radio2') }}</a-radio>
          </a-radio-group>
        </a-form-model-item>

        <a-form-model-item :label="$t('shareModal.address.lookNum')" prop="previewCountLimit">
          <a-select
            size="large"
            :placeholder="$t('shareModal.info.placeholderLookCountLimit')"
            v-model="form.previewCountLimit"
            @change="limitChange"
          >
            <a-select-option
              v-for="i in seleiconlist1"
              :key="i.value"
              :label="i.name + $t('shareModal.address.timesNum')"
            >
              <span :class="i.isShow ? 'label' : ''">{{ i.name }}</span>
              <span v-if="i.isShow">{{$t('shareModal.address.timesNum')}}</span>
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item
          label="  "
          class="labelEmpty"
          v-if="form.previewCountLimit === 0"
          prop="previewCountLimitInput"
        >
          <a-input-number
            style="width: 100%"
            v-model="form.previewCountLimitInput"
            :placeholder="$t('shareModal.info.placeholderLookCountLimit2')"
            :min="1"
            :precision="0"
            :formatter="limitNumber"
            :parser="limitNumber"
          />
        </a-form-model-item>
        <a-form-model-item
          :label="$t('shareModal.address.downloadNum')"
          v-if="form.shareType === '1'"
          prop="downloadCountLimit"
        >
          <a-select
            size="large"
            :placeholder="$t('shareModal.info.placeholderDownloadCountLimit')"
            v-model="form.downloadCountLimit"
            @change="downloadChange"
          >
            <a-select-option
              v-for="i in seleiconlist2"
              :key="i.value"
              :label="i.name + $t('shareModal.address.timesNum')"
            >
              <span :class="i.isShow ? 'label' : ''">{{ i.name }}</span>
              <span v-if="i.isShow">{{ $t('shareModal.address.timesNum') }}</span>
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          label="  "
          class="labelEmpty"
          v-if="form.downloadCountLimit === 1 && form.shareType === '1'"
          prop="downloadCountLimitInput"
        >
          <a-input-number
            style="width: 100%"
            v-model="form.downloadCountLimitInput"
            :placeholder="$t('shareModal.info.placeholderDownloadCountLimit2')"
            :min="1"
            :precision="0"
            :formatter="limitNumber"
            :parser="limitNumber"
          />
          <div
            v-if="form.downloadCountLimit === 1 && form.shareType === '1'"
            style="color: red"
          >
            {{$t('shareModal.info.downCeiling')}}
          </div>
        </a-form-model-item>
        <a-form-model-item label="  " class="labelEmpty">
          <div class="minP">
            {{$t('shareModal.info.downloaded')}}{{
              shareInfo.opusDownLoadCountResultDTO.currentCount || 0
            }}{{ $t('shareModal.address.timesNum') }}
          </div>
        </a-form-model-item>
      </a-form-model>

      <template slot="footer">
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleOk('form')"
        >
        {{ $t('shareModal.info.generateShare') }}
        </a-button>
      </template>
    </a-modal>
    <ShareAddress ref="shareAddress" @shareMethods="shareMethods"/>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'
import { postShreInfo, downCount, getShareInfo } from '@/api/share'
import ShareAddress from '@com/share/shareAddress'
import ShareInfo from '@com/share/shareInfo'

export default {
  components: { ShareAddress },
  data() {
    return {
      visible: false,
      form: {
        accessDuration: 7,
        accessStatus: '0',
        shareType: '0',
        previewCountLimit: 10,
      },
      openDate: false,
      openTimes: true,
      
      loading: false,
      shareInfo: {
        opusDownLoadCountResultDTO: {},
      },
      tableInfo: {},
      unix: '',
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState({
      evidenceType: (state) => state.searchInfo.evideceTypeName,
      shareFileType: (state) => state.shareDto.shareFileType,
    }),
    rules(){
      return {
        accessDuration: [
          { required: true, message: this.$t('shareModal.info.placeholderPeriod'), trigger: 'change' },
        ],
        expiredAt: [
          { required: true, message: this.$t('shareModal.info.placeholderTime'), trigger: 'change' },
        ],
        accessStatus: [
          { required: true, message: this.$t('shareModal.info.varAccessCode'), trigger: 'change' },
        ],
        accessCode: [
          {
            required: true,
            message: this.$t('shareModal.info.placeholderAccessCode'),
            trigger: 'change',
          },
        ],
        shareType: [
          {
            required: true,
            message: this.$t('shareModal.info.varPermission'),
            trigger: 'change',
          },
        ],
        previewCountLimit: [
          {
            required: true,
            message: this.$t('shareModal.info.placeholderLookCountLimit'),
            trigger: 'change',
          },
        ],
        previewCountLimitInput: [
          {
            required: true,
            message: this.$t('shareModal.info.placeholderLookCountLimit2'),
            trigger: 'change',
          },
        ],
        downloadCountLimit: [
          {
            required: true,
            message: this.$t('shareModal.info.placeholderDownloadCountLimit'),
            trigger: 'change',
          },
        ],
        downloadCountLimitInput: [
          {
            required: true,
            message: this.$t('shareModal.info.placeholderDownloadCountLimit2'),
            trigger: 'change',
          },
        ],
      }
    },
    labelCol(){
      return  { span: this.$i18n.locale == 'en'?9:3 }
    },
    wrapperCol(){
      return  { span: this.$i18n.locale == 'en'?13:19 }
    },
    seleiconlist(){return [
        { name: '7', isShow: true, value: 7 },
        { name: '14', isShow: true, value: 14 },
        { name: this.$t('shareModal.info.longTerm'), isShow: false, value: -1 },
        { name: this.$t('shareModal.info.customDate'), isShow: false, value: 0 },
    ]},
    seleiconlist1(){return [
      { name: '10', isShow: true, value: 10 },
      { name: '5', isShow: true, value: 5 },
      { name: this.$t('shareModal.info.NoRestriction'), isShow: false, value: -1 },
      { name: this.$t('shareModal.info.customTimes'), isShow: false, value: 0 },
    ]},
    seleiconlist2(){return [
      { name: '5', isShow: true, value: 5 },
      { name: this.$t('shareModal.info.customTimes'), isShow: false, value: 1 },
    ]},
  },
  watch: {},
  methods: {
    disabledDate(current) {
      return current && current < moment().endOf('day');
    },
    cancel() {},
    duraChange(e) {},
    accessChange(e) {},
    shareChange(e) {},
    limitChange(e) {},
    downloadChange(e) {},
    // accessStatus （0无需访问码 1需要访问码）
    // previewCountLimit 访问次数上限 -1 无限.
    // accessDuration      访问码有效期(天) 0 自定义时间.
    // PREVIEW((byte)0),
    // DOWNLOAD((byte)1);
    async handleOk(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let obj = Object.assign({}, this.form)
          console.log(this.tableInfo)
          let { opusDownLoadCountResultDTO, templateId } = this.shareInfo
          let { isqlws, serialNo, opusName } = this.tableInfo
          let bid =
            this.tableInfo.type === 0 ? this.tableInfo.bid : this.tableInfo.id
          let fileName =
            this.tableInfo.type === 0
              ? this.tableInfo.fileName
              : this.tableInfo.name
          let {
            accessDuration,
            expiredAt,
            accessStatus,
            previewCountLimit,
            previewCountLimitInput,
            downloadCountLimit,
            downloadCountLimitInput,
            shareType,
          } = obj
          // 有效期
          if (accessDuration === 0) {
            obj.expiredAt = this.unix
          } else {
            delete obj.expiredAt
          }
          // 访问码
          // if (accessStatus == 2) {
          //   delete obj.accessStatus
          // }

          if (accessStatus == 2) {
            obj.accessStatus = 1
          }

          // 次数
          if (previewCountLimit === 0) {
            obj.previewCountLimit = previewCountLimitInput
            delete obj.previewCountLimitInput
          }

          // 下载
          if (downloadCountLimit === 1) {
            obj.downloadCountLimit = downloadCountLimitInput
            delete obj.downloadCountLimitInput
          } else if (downloadCountLimit === 0) {
            delete obj.downloadCountLimit
            obj.shareType = 0
          }
          console.log(obj)

          // 权限
          if (shareType == 0) {
            delete obj.downloadCountLimit
          }

          if (
            shareType == 1 &&
            parseInt(obj.downloadCountLimit) +
              parseInt(opusDownLoadCountResultDTO.currentCount) >
              opusDownLoadCountResultDTO.limitCount
          ) {
            this.$message.warning(this.$t('shareModal.info.varDownloadCountLimit'))
            obj.downloadCountLimitInput = ''
            return false
          }

          let { type } = obj
          obj.bid = serialNo
          obj.shareFileType = this.shareFileType
          obj.fileName = opusName
          obj = Object.assign({ templateId, shareType, fileName, bid }, obj)
          obj.shareType === 0
            ? (obj.shareType = 'PREVIEW')
            : obj.shareType === 1
            ? (obj.shareType = 'DOWNLOAD')
            : ''
          try {
            let res = await postShreInfo(obj)
            if (res.shareDTO) {
              this.$message.success(this.$t('shareModal.info.shareSuccessful'))
              let shareRes = await getShareInfo({
                serialNo: res.shareDTO.bid,
              })

              this.$refs.shareAddress.handleShow(shareRes)

              this.visible = false

              this.$emit('shareMethods')
              this.form = {}
              this.form = {
                accessDuration: 7,
                accessStatus: '0',
                shareType: '0',
                previewCountLimit: 10,
              }
            }
          } catch (error) {
            console.log(error)
            this.$message.error(error.message)
          }
          
        }
      })
    },
    evidenceGet(data) {
      this.$emit('evidenceGet', data)
    },
    onChange(dates, dateStrings) {
      dates?this.unix = dates.valueOf() : ''
    },
    onSearch(value) {
      console.log(value)
    },
    async handleShow(data, shareRes) {
      this.visible = true
      let downCountRes = await downCount({ serialNo: data.serialNo })
      this.tableInfo = data
      this.$store.dispatch('shareDto/SET_SHAREFILETYPE', data.opusName)
      this.shareInfo = shareRes
      console.log(downCountRes)
    },

    limitNumber(value) {
      if (typeof value === 'string') {
        return !isNaN(Number(value)) ? value.replace(/\./g, '') : 1
      } else if (typeof value === 'number') {
        return !isNaN(value) ? String(value).replace(/\./g, '') : 1
      } else {
        return 1
      }
    },
    //  停止 开始分享
    shareMethods() {
      console.log(456)
      this.$emit('shareMethods')
    },
  },
  created() {},
  mounted() {},
}
</script>

<style lang="less" scoped>
@import './minLess.less';
</style>
