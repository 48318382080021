import Vue from 'vue'
import Clipboard from 'clipboard'
import { get } from "@/utils/localStorage";

function clipboardSuccess() {
    Vue.prototype.$message.success(get("language")=='en'?'Copy succeeded.':'复制成功')
}

function clipboardError() {
    Vue.prototype.$message.error('复制失败')
}

/**
 * @description 复制数据
 * @param text
 * @param event
 */
export default function handleClipboard(text, event) {
    const clipboard = new Clipboard(event.target, {
        text: () => text,
    })
    clipboard.on('success', () => {
        clipboardSuccess()
        clipboard.destroy()
    })
    clipboard.on('error', () => {
        clipboardError()
        clipboard.destroy()
    })
    clipboard.onClick(event)
}
