<template>
  <div>
    <a-modal
      v-model="visible"
      :title="$t('shareModal.address.title')"
      @ok="handleOk"
      :maskClosable="false"
      width="680px"
    >
      <a-row type="flex" justify="start" class="top">
        <a-col :span="8">
          <p class="title">{{$t('shareModal.address.wxScan')}}</p>
          <div class="imgInfo">
            <img
              :src="addressInfo.qrCodeBase64"
              alt=""
              class="imageView bgCommon"
            />
          </div>
        </a-col>
       
        <a-col :span="1">
          <a-divider type="vertical" />
        </a-col>
        <a-col :span="1"></a-col>

        <a-col :span="13">
          <div class="title" style="text-align: left;">{{$t('shareModal.address.shareLink')}}</div>
          <div class="url">{{ addressInfo.url }}</div>
          <a-button type="primary" @click="handleCopy(3, $event)">
            {{$t('shareModal.address.copyShareLink')}}
          </a-button>
          <a-button
            type="primary"
            @click="handleCopy(1, $event)"
            style="margin-left: 30px"
          >
          {{$t('shareModal.address.copyShareFont')}}
          </a-button>
        </a-col>
      </a-row>
      <a-divider class="mid" />
      <a-row type="flex" justify="start" class="bot">
        <a-col :span="this.$i18n.locale == 'en'?11:7">
          <p>{{$t('shareModal.address.certificateSharing')}}</p>
          <p @click="handleDownCertificate">{{$t('shareModal.address.download')}}</p>
        </a-col>
        <a-col :span="this.$i18n.locale == 'en'?11:7">
          <p>{{$t('shareModal.address.evidencePoster')}}</p>
          <p @click="handleDownload">{{$t('shareModal.address.download')}}</p>
        </a-col>
        <a-col :span="this.$i18n.locale == 'en'?22:7" :style="this.$i18n.locale == 'en'?'padding-top:11px':''">
          <p>
            {{$t('shareModal.address.accessCode')}}{{
              addressInfo.accessCode ? addressInfo.accessCode : $t('shareModal.address.noAccessCode')
            }}
          </p>
          <p @click="handleCopy(2, $event)" v-if="addressInfo.accessCode">
            {{$t('shareModal.address.copy')}}
          </p>
        </a-col>
      </a-row>
      <a-row type="flex" justify="start" class="bot">
        <a-col :span="24">
          <p v-if="addressInfo.accessDuration == -1">{{$t('shareModal.address.validityPeriod')}}{{$t('shareModal.address.longTerm')  }}</p>
          <p v-else>{{$t('shareModal.address.validityPeriod')}}{{ addressInfo.expiredAt }}</p>
          <p @click="stopShare">{{$t('shareModal.address.stopeShare')}}</p>
        </a-col>
      </a-row>
      <a-row type="flex" justify="start" class="bot">
        <a-col :span="this.$i18n.locale == 'en'?22:7">
          <p class="bot">
            {{$t('shareModal.address.lookNum')}}{{
              addressInfo.previewCountLimit === -1
                ? $t('shareModal.address.confine')
                : addressInfo.previewCountLimit -
                  addressInfo.previewCount +
                  $t('shareModal.address.timesNum')
            }}
          </p>
        </a-col>
        <a-col :span="this.$i18n.locale == 'en'?22:7">
          <p class="bot">
            {{$t('shareModal.address.downloadNum')}} {{
              addressInfo.shareType === 1
                ? addressInfo.enableDownLoadCount + $t('shareModal.address.timesNum')
                : $t('shareModal.address.noDownload')
            }}
          </p>
        </a-col>
        <a-col :span="this.$i18n.locale == 'en'?22:7">
          <p class="bot">{{$t('shareModal.address.downloadedNum')}}{{ addressInfo.downloadCount }}{{$t('shareModal.address.timesNum')}}</p>
        </a-col>
      </a-row>

      <template slot="footer">
        <a-button key="submit" type="primary" @click="visible = false">
          {{$t('shareModal.address.close')}}
        </a-button>
      </template>
    </a-modal>
    <div class="bgCode" id="mycanvas" v-show="showCode" ref="content">
      <img :src="posterBgImg" alt="" class="QRori1" crossorigin="anonymous" />
      <div
        v-if="
          addressInfo.originalCoverUrl ==
            'http://tsa-public-test.oss-cn-beijing.aliyuncs.com/shared/1.png'
        "
      >
        <img :src="addressInfo.originalCoverUrl" alt="" class="QRori" crossorigin="anonymous" />
      </div>

      <div
        v-if="
          addressInfo.originalCoverUrl ==
            'http://tsa-public-test.oss-cn-beijing.aliyuncs.com/shared/3.png'
        "
      >
        <img :src="addressInfo.originalCoverUrl" alt="" class="QRori" crossorigin="anonymous" />
      </div>
      <div
        v-if="
          addressInfo.originalCoverUrl !=
            'http://tsa-public-test.oss-cn-beijing.aliyuncs.com/shared/3.png' &&
            addressInfo.originalCoverUrl !=
              'http://tsa-public-test.oss-cn-beijing.aliyuncs.com/shared/1.png'
        "
      >
        <img
          :src="addressInfo.originalCoverUrl"
          alt=""
          class="QRori isORori"
          crossorigin="anonymous"
        />
      </div>
      <p class="fileName">{{ hiddenFileName }}</p>
      <img
        :src="addressInfo.pdfThumbnailUrl"
        alt=""
        class="QRpdf"
        crossorigin="anonymous"
      />
      <img
        :src="addressInfo.qrCodeBase64"
        alt=""
        class="QrcodeBg"
        crossorigin="anonymous"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import html2canvas from 'html2canvas'
import clip from '@/config/clipboard'
import { parseTime } from '@/utils/filter'
import { forbiddenShare } from '@/api/share'

export default {
  components: {},
  data() {
    return {
      visible: false,
      imageView: require('@/assets/images/share/buy.png'),
      addressInfo: {
        qrCodeBase64:''
      },
      imgUrl: '',

      posterBgImg: require('@/assets/images/share/poster.jpg'),
      audioBgImg: require('@/assets/images/share/audio.png'),
      videoImg: require('@/assets/images/share/video.png'),
      qrcodeImg: '',
      pdfThumbnailUrl: '',
      posterSize: 930 / 650, // 海报高宽比例
      qrCodeSize: {
        // 二维码与海报对应比例 =》 用于设置二维码在海报中的位置
        width: 270 / 650,
        height: 270 / 930,
        left: 190 / 650,
        top: 448 / 650,
      },
      poster: '', // 合成图片
      showCode: false,
      formInfo: {},
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState({
      evidenceType: (state) => state.searchInfo.evideceTypeName,
    }),
    screenWidth() {
      let w =
        document.body.clientWidt || document.documentElement.clientWidth || 375
      return w > 480 ? 480 : w
    },
    hiddenFileName() {
      let str = this.addressInfo.fileName
      let textLeng = 16
      // console.log(str)
      if (str) {
        if (str.length > textLeng) {
          str = str.substring(0, textLeng) + '...'
        }
      }

      return str
    },
  },
  watch: {},
  methods: {
    handleOk(e) {
      console.log(e)
      this.visible = false
    },
    handleCopy(type, event) {
      let { url, account, fileName, accessCode } = this.addressInfo
      let text
      if (type === 1) {
        if (accessCode) {
          text = `${this.$t('shareModal.address.shareCopywriting.node1')}${account}${this.$t('shareModal.address.shareCopywriting.node2')}“${fileName}”${this.$t('shareModal.address.shareCopywriting.node3')}${url}${this.$t('shareModal.address.shareCopywriting.node4')}${accessCode}`
        } else {
          text = `${this.$t('shareModal.address.shareCopywriting.node1')}${account}${this.$t('shareModal.address.shareCopywriting.node2')}“${fileName}”${this.$t('shareModal.address.shareCopywriting.node3')}${url}`
        }
      } else if (type === 3) {
        text = url
      } else {
        text = accessCode
      }
      clip(text, event)
    },
    handleShow(data, formInfo) {
      this.addressInfo = data.shareDTO
      this.addressInfo.templateId = data.templateId
      this.addressInfo.qrCodeBase64 = `data:image/jpg;base64,${this.addressInfo.qrCodeBase64}`
      if (this.addressInfo.expiredAt) {
        this.addressInfo.expiredAt = parseTime(this.addressInfo.expiredAt)
      }
      this.visible = true

      console.log(this.addressInfo)
    },
    async stopShare() {
      let { bid, templateId } = this.addressInfo
      let res = await forbiddenShare({ bid, templateId })
      if (res.code === 200) {
        this.$emit('shareMethods')
        this.$message.success(this.$t('shareModal.address.stopShareSuccessful'))
        this.visible = false
      }
      console.log(this.addressInfo)
    },
    handleDownload() {
      // 先获取你要转换为img的dom节点
      this.$message.success(this.$t('shareModal.address.syntheticPosters'))
      this.showCode = true
      let that = this
      setTimeout(() => {
        var node = document.getElementById('mycanvas') // 传入的id名称
        console.log(node)
        var width = node.offsetWidth // dom宽
        var height = node.offsetHeight // dom高
        console.log(width, height)
        var scale = 10 // 放大倍数 这个相当于清晰度 调大一点更清晰一点
        html2canvas(this.$refs.content, {
          width: 210,
          heigtht: 613,
          proxy: this.addressInfo.originalCoverUrl,
          dpi: window.devicePixelRatio * 10, // 按屏幕像素比增加像素
          scale: scale,
          useCORS: true, // 是否使用CORS从服务器加载图像 !!!
          allowTaint: false, // 是否允许跨域图像污染画布  !!!
        }).then((resolve) => {
          let imgUrl = resolve.toDataURL('image/png') //此时就得到了dom元素转成了base64的图片
          function dataURItoBlob(dataURI) {
            var mimeString = dataURI
              .split(',')[0]
              .split(':')[1]
              .split(';')[0] // mime类型
            var byteString = atob(dataURI.split(',')[1]) //base64 解码
            var arrayBuffer = new ArrayBuffer(byteString.length) //创建缓冲数组
            var intArray = new Uint8Array(arrayBuffer) //创建视图

            for (var i = 0; i < byteString.length; i++) {
              intArray[i] = byteString.charCodeAt(i)
            }
            return new Blob([intArray], { type: mimeString })
          }

          function getObjectURL(file) {
            var url = null
            if (window.createObjectURL !== undefined) {
              // basic
              url = window.createObjectURL(file)
            } else if (window.URL !== undefined) {
              // mozilla(firefox)
              url = window.URL.createObjectURL(file)
            } else if (window.webkitURL !== undefined) {
              // webkit or chrome
              url = window.webkitURL.createObjectURL(file)
            }
            return url
          }

          var link = document.createElement('a')
          link.target = '_blank'
          link.href = getObjectURL(dataURItoBlob(imgUrl))
          link.id = 'href'
          document.body.appendChild(link)
          link.download = that.addressInfo.fileName + '_分享海报.jpg'

          var event = new MouseEvent('click') // 增加一个点击事件
          link.dispatchEvent(event) // 触发a的单击事件 即可完成下载
        }, 100)
      }, 200)
    },
    handleDownCertificate(){
      window.open(this.addressInfo.pdfUrl)
    }
  },
  created() {},
  mounted() {},
}
</script>

<style lang="less" scoped>
@import './minLess.less';

div /deep/ .ant-modal-body {
  padding: 21px 24px;
}

.imgInfo {
  width: 100%;
  height: 130px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageView {
  width: 130px;
  height: 130px;
}

.title {
  text-align: center;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  margin-bottom: 10px;
}

.url {
  padding: 9px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  background: #f0f0f0;
  border-radius: 5px;
  margin-bottom: 10px;
}

.top /deep/ .ant-divider,
.ant-divider-vertical {
  height: 100%;
}

.mid {
  margin: 17px 0;
}

.bot {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-bottom: 11px;

  p {
    display: inline-block;

    &:nth-child(2) {
      color: #258cf5;
      margin-left: 10px;
      cursor: pointer;
    }
  }
}

.bgCode {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: -10;
  width: 375px;
  height: 719px;
  // background: url('~@/assets/img/share/poster.jpg') no-repeat;
  background-size: contain;

  .fileName {
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    position: absolute;
    bottom: 95px;
    transform: scale(0.9, 0.83);
    left: -4px;
    top: 256px;
    width: 57%;
    height: 17px;
    overflow-y: hidden;

    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    float: left;
  }

  .QrcodeBg {
    position: absolute;
    bottom: 8px;
    left: 7px;
    width: 49px;
    height: 49px;
  }

  .QRpdf {
    position: absolute;
    bottom: 113px;
    left: 0px;
    width: 210px;
    height: 298px;
  }

  .QRori {
    position: absolute;
    top: 121px;
    left: 0px;
    width: 210px;
    height: 129px;
  }

  .isORori {
    border: 8px solid #ebf3fe;
  }

  .QRori1 {
    width: 210px;
    height: 719px;
  }
}
</style>
