<template>
  <div>
    <a-modal
      v-model="visible"
      :title="$t('protectFile.billDiaBox.title')"
      @ok="handleOk"
      :maskClosable="false"
      :confirm-loading="confirmLoading"
      width="40%"
    >
      <div>
        <i class="el-icon-warning"></i>
        <p>{{ resText }}</p>
      </div>
      <template slot="footer">
        <a-button key="back" @click="visible = false"> {{ $t('diaBtn.cancel') }}</a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleOk(resType)"
        >
          {{
            resType === 0 || resType === 2
              ? $t('diaBtn.confirm')
              : resType === 1
              ? $t('diaBtn.certification')
              : ''
          }}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
function hrefOpen(url) {
  var link = document.createElement('a')
  link.target = '_blank'
  link.href = url
  link.id = 'href'
  document.body.appendChild(link)
  link.click()
}
export default {
  components: {},
  props: {
    searchParam: {type: Object,default: {}},
  },
  data() {
    return {
      visible: false,
      visible2: false,
      loading: false,
      value: '',
      confirmLoading: false,
      tableInfo: {},
      resInfo: {},
      resText: '',
      resType: '',
    }
  },
  computed: {
    ...mapGetters([]),
  },
  watch: {},
  methods: {
    async handleOk(resType) {
      if (resType === 1) {
        hrefOpen(
          process.env.VUE_APP_ACCOUNTUI +
            '/userCenter/AuthenticationName?clientId=952310'
        )
      }else{
        this.visible = false;
        console.log(this.searchParam)
        if(JSON.stringify(this.searchParam)!=='{}'){
          this.$router.push({
              path: "/time/FileDetail",
              query: this.searchParam,
          });
        }
      }

      
    },
    // dataType 0 未上传原件 1未实名 2子账号
    handleShow(dataInfo, dataText, dataType) {
      this.visible = true
      this.resInfo = dataInfo
      this.resText = dataText
      this.resType = dataType
    },
  },
  created() {},
  mounted() {},
}
</script>

<style lang="less" scoped>
@import './minLess.less';
div /deep/ .ant-modal-body {
  padding: 21px 22px 10px;
}
div {
  text-align: center;

  i {
    font-size: 43px;
    color: #ff6600;
    display: block;
    margin: 20 0;
  }
}

p {
  text-align: center;
  line-height: 45px;
  font-size: 15px;
}
</style>
